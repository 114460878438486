import { ApiResponse } from '@/models/api'
import client from './index'

import { CreateAdminAccount, SecurityGroupItem } from '@/models/securityGroup'

export default {
  usersList() {
    return client.get<ApiResponse<SecurityGroupItem[]>>('')
  },

  inviteUser(params: CreateAdminAccount) {
    return client.post<ApiResponse<null>>('', params)
  },

  updateUserStatus(
    id: string,
    params: Pick<SecurityGroupItem, 'role' | 'status'>
  ) {
    return client.put<ApiResponse<SecurityGroupItem>>(`/${id}`, params)
  },
}
