import { computed } from 'vue'

import { getScope, RbacAction, RbacEntity } from '@/helpers/rbac'

import { useAuthStore } from '@/store/auth'

function useScopeCheck(action: RbacAction, entity: RbacEntity) {
  const authStore = useAuthStore()

  return computed(() => authStore.scopes.includes(getScope(action, entity)))
}

export default useScopeCheck
